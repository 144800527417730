<template>
  <section>
    <v-layout row wrap v-if="showStampaCartello">
      <v-flex xs12>
        <v-btn class="mr-0" style="float:right;" @click="showBancali = true">
          Stampa cartello
        </v-btn>
      </v-flex>
    </v-layout>
    <v-list two-line v-if="destinazioni.length">
      <template v-for="(destinazione, index) in destinazioni">
        <v-list-tile
          @click.stop="openDestDialog(destinazione)"
          :key="'tile-'+destinazione._id">
          <v-list-tile-content>
            <v-list-tile-title v-text="getAddr(destinazione)" />
            <v-list-tile-sub-title v-text="'Bancali: '+destinazione.bancali.length" />
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text v-text="'Qtà '+destinazione.qta" />
            <v-icon @click.stop="openEliminaDest(destinazione)" v-if="canDel(destinazione)" color="error">delete</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="'divider-'+destinazione._id" v-if="index < destinazioni.length - 1" />
      </template>
    </v-list>
    <v-alert
      outline=""
      color="info"
      value="true"
      v-if="!destinazioni.length">
      Nessuna destinazione trovata
    </v-alert>
    <!-- modale destinazione -->
    <v-dialog
      v-model="showDest"
      transition="dialog-bottom-transition"
      :overlay="false"
      max-width="900">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title v-if="destinazione.nuovo">Nuova destinazione</v-toolbar-title>
          <v-toolbar-title v-else>Modifica destinazione</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.stop="showDest=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formDest" v-model="validformDest" lazy-validation>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex md6>
                  <v-select
                    label="Destinazione"
                    :items="destinazioniCli"
                    item-text="presso"
                    item-value="_id"
                    v-model="destinazione.destinazione_id"
                    :disabled="destReadOnly()" />
                </v-flex>
                <v-flex md6>
                  <v-select
                    label="Modalita imballo"
                    v-model="destinazione.tipo_imballo"
                    :disabled="destReadOnly()"
                    :items="modalita"
                    item-text="descr"
                    item-value="id" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex md12>
                  <v-textarea
                    auto-grow
                    label="Note"
                    name="note"
                    v-model="destinazione.note"
                    :disabled="destReadOnly()"
                    :rules="[$rules.maxlength(60)]" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex md6>
                  <v-text-field
                    label="Copie per imballo"
                    v-model="destinazione.copie"
                    name="copie"
                    :disabled="destReadOnly()"
                    :rules="[$rules.numeric, $rules.maxlength(4)]" />
                  <v-text-field
                    label="Imballi per piano"
                    v-model="destinazione.imballi"
                    name="imballi"
                    :disabled="destReadOnly()"
                    :rules="[$rules.numeric, $rules.maxlength(4)]" />
                  <v-text-field
                    label="Piani per bancale"
                    v-model="destinazione.piani"
                    name="piani"
                    :disabled="destReadOnly()"
                    :rules="[$rules.numeric, $rules.maxlength(2)]" />
                  <v-text-field
                    label="Quantità"
                    v-model="destinazione.qta"
                    name="qta"
                    :disabled="destReadOnly()"
                    :rules="[$rules.numeric]" />
                  <v-btn
                    class="ml-0"
                    color="primary"
                    :disabled="destReadOnly() || !destinazione.qta || !destinazione.copie || !destinazione.imballi || !destinazione.piani"
                    id="calcolaBancali"
                    @click.native="calcola">
                    Calcola bancali
                  </v-btn>
                </v-flex>
                <v-flex md5>
                  <template v-for="(bancale, index) in destinazione.bancali">
                    <v-layout row wrap :key="'tile-bancali-'+index">
                      <v-flex xs11 md5>
                        <v-text-field
                          :value="'Bancale '+(index+1)"
                          disabled />
                      </v-flex>
                      <v-flex xs11 md5>
                        <v-text-field
                          v-model="bancale.qta"
                          :disabled="destReadOnly() || !canDelBanc(bancale)"
                          :rules="[$rules.required, $rules.numeric, $rules.maxlength(7)]" />
                      </v-flex>
                      <v-flex xs2 md1>
                        <v-icon @click="deleteBancale(bancale, index)" v-if="canDelBanc(bancale)" color="error">delete</v-icon>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    class="ml-0"
                    color="primary"
                    :disabled="destReadOnly() || !validformDest"
                    id="salvaDestinazione"
                    @click.native="save()">
                    Salva destinazione
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- conferma elimina destinazione -->
    <modal-confirm ref="confirm" />
    <!-- Stampa cartello -->
    <v-dialog v-model="showBancali" transition="dialog-bottom-transition" :overlay="false" max-width="900" scrollable>
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>
            Stampa cartello
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="" color="primary" :disabled="!selectedBancali.length" @click="stampa()">Stampa</v-btn>
          <v-btn icon @click.stop="showBancali = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text v-if="destinazioni.length">
          <v-list key="allDest">
            <v-list-tile avatar key="tile-all">
              <v-list-tile-avatar>
                <v-checkbox v-model="allSelected" @change="selectAll" />
              </v-list-tile-avatar>
              <v-list-tile-content v-text="'Tutte le destinazioni'" />
            </v-list-tile>
            <v-divider />
          </v-list>
          <v-form ref="formBancali">
            <v-container fluid>
              <template v-for="dest in destinazioni" v-if="dest.bancali.length">
                <v-list :key="'tile-dest-'+dest._id" dense>
                  <v-list-tile avatar :key="'tile-dest-avat-'+dest._id">
                    <v-list-tile-avatar>
                      <v-checkbox v-model="selectedDests" @change="selectDest(dest)" :value="dest._id" />
                    </v-list-tile-avatar>
                    <v-list-tile-content v-text="getAddr(dest)" />
                  </v-list-tile>
                </v-list>
                <template v-for="(bancale, index) in dest.bancali">
                  <v-list :key="'tile-dest-banc-'+bancale._id" dense class="ml-4">
                    <v-list-tile avatar :key="'tile-dest-banc-avat-'+bancale._id">
                      <v-list-tile-avatar>
                        <v-checkbox v-model="selectedBancali" :value="bancale._id" />
                      </v-list-tile-avatar>
                      <v-list-tile-content v-text="'Bancale ' + (index + 1)" />
                    </v-list-tile>
                  </v-list>
                </template>
              </template>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-menu left offset-x v-if="!readOnly()">
      <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openDestDialog()">
        <v-icon color="white">add</v-icon>
      </v-btn>
    </v-menu>
  </section>
</template>

<script>
import { $EventBus } from '@/eventBus'
import _clone from 'lodash/clone'
import _reduce from 'lodash/reduce'
import _findIndex from 'lodash/findIndex'
import _intersection from 'lodash/intersection'
import _map from 'lodash/map'
import modalConfirm from '@/components/common/ModalConfirm'

export default {
  props: {
    ordine: Object
  },
  components: {
    'modal-confirm': modalConfirm
  },
  data: () => ({
    selectedBancali: [],
    destinazioni: [],
    destinazioniCli: [],
    selectedDests: [],
    allSelected: false,
    destinazione: {},
    modalita: [{ descr: 'Reggiatura', id: 0 }, { descr: 'Inscatolatura', id: 1 }, { descr: 'Fascettatura', id: 2 }],
    showDest: false,
    showBancali: false,
    validformDest: false,
    generatingPdf: false
  }),
  computed: {
    showStampaCartello () {
      return _reduce(this.destinazioni, (sum, elem) => sum + elem.qta, 0) > 0
    }
  },
  methods: {
    getDestinazioniCliente () {
      this.$plsqlReadAll('destinazione', { tipo: 0, cliente: this.ordine.cliente_id, attivo: 1 })
        .then(response => { this.destinazioniCli = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    getDestinazioni () {
      this.$plsqlMethod('ordine', 'get_destinazioni', { ordine: this.ordine._id, cliente: this.ordine.cliente_id })
        .then(response => { this.destinazioni = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    save () {
      if (this.$refs.formDest.validate() && !this.readOnly()) {
        this.showDest = false
        if (!this.destinazione.bancali) this.destinazione.bancali = []
        this.destinazione.ordine_id = this.ordine._id
        this.$plsqlMethod('ordine', 'save_bancali', this.destinazione)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Destinazione salvata'
            })
            this.getDestinazioni()
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      }
    },
    removeDest () {
      if (!this.readOnly()) {
        this.$plsqlMethod('ordine', 'delete_bancali', { ordini_destinazioni_id: this.destinazione._id })
          .then(response => {
            $EventBus.$emit('message', { type: 'success', text: 'Destinazione eliminata' })
            this.$refs.confirm.hide()
            this.showDest = false
            this.getDestinazioni()
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      }
    },
    openEliminaDest (d) {
      this.destinazione = d
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'Stai per cancellare la destinazione <strong>' + this.destinazione.presso + '</strong>.<p><strong>ATTENZIONE</strong>: verranno cancellati i bancali associati alla destinazione.</p>',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.removeDest,
        modalObj: this.destinazione
      })
    },
    getAddr (d) {
      let addr = d.presso
      if (d.indirizzo || d.cap || d.citta) addr += ', ' + d.indirizzo + ' ' + d.cap + ' ' + d.citta
      if (d.provincia) addr += ' (' + d.provincia + ')'
      if (d.stato) addr += ' - ' + d.stato
      if (d.note) addr += ' - ' + d.note
      return addr
    },
    canDel (d) {
      return !this.readOnly() && !d.bancali.find(function (b) { return b.ddt_id !== null })
    },
    canDelBanc (b) {
      return !b.ddt_id
    },
    deleteBancale (b, i) {
      this.destinazione.bancali.splice(i, 1)
    },
    openDestDialog (destinazione) {
      if (destinazione) {
        this.destinazione = _clone(destinazione)
        if (destinazione.bancali && destinazione.bancali.length) {
          this.destinazione.imballo_id = destinazione.bancali[0].imballo_id
        }
      } else {
        this.destinazione = { nuovo: 1 }
        this.$refs.formDest.reset()
      }
      this.showDest = true
    },
    calcola () {
      if (!this.readOnly() && this.destinazione.imballi && this.destinazione.piani && this.destinazione.copie) {
        let newBancali = []
        let qtaFisse = 0
        let qta = 0
        let qtaBancale = this.destinazione.imballi * this.destinazione.piani * this.destinazione.copie

        if (this.destinazione.bancali && this.destinazione.bancali.length) {
          newBancali = this.destinazione.bancali.filter(b => b.ddt_id)
          newBancali.forEach(function (b) { qtaFisse += b.qta })
        }

        if (qtaBancale > 0 && this.destinazione.qta > qtaFisse) {
          qta = this.destinazione.qta - qtaFisse
          while (qta > 0) {
            newBancali.push({ qta: Math.min(qta, qtaBancale) })
            qta -= qtaBancale
          }
        }

        this.$set(this.destinazione, 'bancali', newBancali)
      } else {
        $EventBus.$emit('message', { type: 'error', text: 'Dati imballo incompleti' })
      }
    },
    selectDest (dest) {
      let _this = this
      dest.bancali.forEach(function (b) {
        let idx = _this.selectedBancali.indexOf(b._id)
        if (_this.selectedDests.indexOf(dest._id) === -1) {
          if (idx > -1) _this.selectedBancali.splice(idx, 1)
        } else {
          if (idx === -1) _this.selectedBancali.push(b._id)
        }
      })
    },
    selectAll () {
      let _this = this
      if (this.allSelected) {
        this.destinazioni.forEach(function (d) {
          if (_this.selectedDests.indexOf(d._id) === -1) _this.selectedDests.push(d._id)
          _this.selectDest(d)
        })
      } else {
        this.selectedBancali = []
        this.selectedDests = []
      }
    },
    destReadOnly () {
      return (!this.ordine.non_spedito && this.destinazione.bancali && this.destinazione.bancali.find((b) => b.ddt_id))
    },
    readOnly () {
      return (!this.ordine.non_spedito)
    },
    stampa () {
      let _this = this
      let destinazioni = []
      this.generatingPdf = true

      if (this.selectedBancali && this.selectedBancali.length) {
        this.destinazioni.filter((d) => { return _intersection(_map(d.bancali, '_id'), _this.selectedBancali).length > 0 }).forEach(function (d) {
          let selectedbancaliofD = _intersection(_map(d.bancali, '_id'), _this.selectedBancali)
          d.numBancali = d.bancali.length
          d.bancaliPrint = []
          selectedbancaliofD.forEach(function (s) {
            let bancale = d.bancali.find((b) => b._id === s)
            bancale.id = s
            bancale.num = _findIndex(d.bancali, (b) => b._id === s) + 1
            d.bancaliPrint.push(bancale)
          })
          destinazioni.push(d)
        })
        this.$http({
          method: 'post',
          url: `/pdf/cartello`,
          responseType: 'blob',
          data: { selectedBancali: this.selectedBancali, ordine: this.ordine, destinazioni: destinazioni }
        })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            this.$openFileNewTab(blob) // con adblocker picche ocio
            this.generatingPdf = false
          })
          .catch(err => {
            console.log('errore anteprima pdf', err)
            $EventBus.$emit('message', { type: 'error', text: 'errore anteprima pdf' })
            this.generatingPdf = false
          })
      }
    }
  },
  mounted () {
    this.getDestinazioni()
    this.getDestinazioniCliente()
  }
}
</script>
